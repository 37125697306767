/*!
 *
 *  *
 *  *  Copyright (C) THL A29 Limited, a Tencent company. All rights reserved.
 *  *  SPDX-License-Identifier: Apache-2.0
 *  *
 *
 */
 body{
  background-image: url(/assets/imgs/indexbg.png);
  background-position: top;
  background-repeat: no-repeat;
  background-size: 100%;
  background-color: #f4faff;
}
.header{
  height:56px;
  background: rgba(255,255,255,0.5);
}
.header_c{
  height:56px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 60px;
  margin-right: 60px;
}
.header_logo{
  width:350px;
  height:53px;
  cursor: pointer;
}
.header_setting{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width:295px;
  cursor: pointer;
}
.header_seeting_icon{
  cursor: pointer;
}

@media screen and(max-width: 415px) {
  .header_c{
    height:56px;
    width:100%;
  }
  .header_logo{
    width: 285px;
    height: 36px;
    cursor: pointer;
  }
  .header_c{
    margin-left: 0px;
  }
}