/*
 *
 *  *
 *  *  Copyright (C) THL A29 Limited, a Tencent company. All rights reserved.
 *  *  SPDX-License-Identifier: Apache-2.0
 *  *
 *
 */

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #F5F8FA;
  /* 防止页面宽度抖动 */
  overflow-y:scroll;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  /* min-width: 1300px; */
  max-width: 1280px;
  margin: auto;
}

.empty_list {
  display: block;
  width:100%;
  min-height: 350px;
  background-image: url(./assets/imgs/empty.png);
  background-position: center;
  background-repeat: no-repeat;
}

.single_line {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@media screen and (max-width: 415px){
  #root{
    overflow: hidden;
  }
}